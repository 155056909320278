<script>
  import { push } from "svelte-spa-router";
  const baseUrl = window.location.host;
  let overlayId;

  function goToOverlay() {
    if (!overlayId) return;
    push(`#/overlay/${overlayId}`);
  }
</script>

<h2 class="mt-3">Welcome mod!</h2>
<p class="mb-0">
  I think you may be lost. Your streamer should have given you a link that
  contained an overlay ID.
  <br />
  Please double check with your streamer for a complete URL that looks like
  <b>{baseUrl}/#/overlay/abc123</b>
</p>

<div class="separator my-3 fs-4">OR</div>

<p>If you know the overlay ID, enter it here.</p>
<form on:submit|preventDefault={goToOverlay}>
  <div class="input-group">
    <input
      type="text"
      name="overlay-id"
      id="overlay-id"
      class="form-control"
      placeholder="Enter your overlay ID"
      bind:value={overlayId}
    />
    <button class="btn btn-primary" on:click={goToOverlay} disabled={!overlayId}
      >Go to overlay</button
    >
  </div>
</form>

<style>
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .separator::before,
  .separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #000;
  }

  .separator:not(:empty)::before {
    margin-right: 0.25em;
  }

  .separator:not(:empty)::after {
    margin-left: 0.25em;
  }

  .input-group {
    width: 100%;
    max-width: 400px;
  }
</style>
