<script>
  import "bootstrap";
  import Router from "svelte-spa-router";
  import routes from "./routes.js";
  import Firebase from "./Firebase.svelte";
  import Nav from "./Nav.svelte";
  import Footer from "./Footer.svelte";
  import { userState } from "./stores";

  let firebaseError;

  function handleFirebaseError(event) {
    console.error(event.detail);
    firebaseError = event.detail;
  }
</script>

<Firebase on:error={handleFirebaseError} />
<Nav />
<main class="container">
  {#if $userState.uid}
    <Router {routes} />
  {:else if firebaseError}
    <div class="alert alert-danger mt-5" role="alert">
      <h4 class="alert-heading">Oh No!</h4>
      <p>{firebaseError} Please try again later.</p>
      <hr />
      <p>Its probably Dave's fault. It's always Dave.</p>
    </div>
  {:else}
    <div class="d-flex flex-column align-items-center mt-5 text-primary">
      <div class="spinner-border">
        <span class="visually-hidden">Loading</span>
      </div>
      <p class="mt-3">Summoning portal</p>
    </div>
  {/if}
</main>
<Footer />

<style type="text/scss" global>
  @import "./styles.scss";

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 60px 12px 32px 12px;
  }

  .spinner-border {
    opacity: 0.5;
  }

  body {
    background-color: var(--bs-secondary-lightest);
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
</style>
