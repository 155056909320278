<script>
  const baseUrl = window.location.host;
</script>

<h2 class="mt-3">Oops... You are definitely lost.</h2>
<p>
  This is NOT an overlay page. <br />
  Please double check with your streamer for a complete URL that looks like
  <b>{baseUrl}/#/overlay/12345</b>
</p>
