<script>
  import firebase from "firebase/app";
  import { createEventDispatcher } from "svelte";

  export let activeOverlay;
  export let user;
  const dispatch = createEventDispatcher();
  let editorBgColor = "#212529";
  let editorBgOpacity = 100;
  let prevOverlay;
  let quill;
  let quillDelta;
  let initialLoad = true;
  let isDirty = false;
  let lastUpdatedBy;

  $: {
    if (activeOverlay !== prevOverlay) {
      console.log("Overlay changed", { overlay: activeOverlay, prevOverlay });
      if (quill) {
        if (
          !initialLoad &&
          isDirty &&
          activeOverlay.lastUpdatedBy.uid !== user.uid
        ) {
          // Prompt user if they want to update changes
          promptWithChanges();
        } else {
          insertQuillValue(
            JSON.parse(activeOverlay.quillDelta),
            activeOverlay.lastUpdatedBy,
            initialLoad
          );
          initialLoad = false;
        }
      }
      prevOverlay = activeOverlay;
    }
  }

  function initializeQuill() {
    console.log("initializing Quill");
    quill = new Quill("#editor", {
      theme: "snow",
      modules: {
        toolbar: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"], // toggled buttons
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["clean"], // remove formatting button
        ],
      },
    });

    // Adding custom color handler function so I can have white text as default
    quill.getModule("toolbar").addHandler("color", (value) => {
      console.log(value);
      if (!value) quill.format("color", "#000000");
      else quill.format("color", value);
    });

    // Subscribe to quill value changes and enable reset button if dirty
    quill.on("text-change", function (delta, oldDelta, source) {
      if (source === "user" && delta !== prevOverlay.quillDelta) {
        isDirty = true;
      }
    });

    // Check if activeOverlay exists and load its content
    // set initialLoad to false
    if (activeOverlay.quillDelta) {
      insertQuillValue(
        JSON.parse(activeOverlay.quillDelta),
        activeOverlay.lastUpdatedBy,
        initialLoad
      );
      initialLoad = false;
    }

    updateEditorBackgroundPos();
  }

  // TODO: on successful update by current user, display success alert
  // Clear after 5s

  function insertQuillValue(delta, lastUpdatedBy = {}, init = false) {
    console.log("Settings value to ", delta);
    quill.setContents(delta);

    if (!init && lastUpdatedBy.uid && lastUpdatedBy.uid !== user.uid) {
      showLastUpdatedBy(lastUpdatedBy.username);
    }

    isDirty = false;
  }

  function promptWithChanges() {
    if (
      confirm(
        `The overlay was just updated by ${
          activeOverlay?.lastUpdatedBy?.username || "someone"
        }. Load latest changes?`
      )
    ) {
      insertQuillValue(
        JSON.parse(activeOverlay.quillDelta),
        activeOverlay.lastUpdatedBy
      );
    }
  }

  function resetQuill() {
    insertQuillValue(JSON.parse(prevOverlay.quillDelta));
  }

  function showLastUpdatedBy(name) {
    lastUpdatedBy = name;
    setTimeout(() => (lastUpdatedBy = null), 10000);
  }

  function updateQuillValue() {
    if (!quill) return;
    console.log(quill.getContents());
    quillDelta = JSON.stringify(quill.getContents());
    console.log(quill.root.innerHTML);
    const htmlStr = JSON.stringify(quill.root.innerHTML);
    dispatch("update", {
      htmlStr,
      quillDelta,
      editorBgColor,
      editorBgOpacity,
      lastUpdatedBy: {
        uid: user.uid,
        username: user.username,
      },
      lastUpdatedTime: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  function updateEditorBgColor() {
    const bg = document.getElementById("editor-background");
    bg.style.backgroundColor = editorBgColor;
  }

  function updateEditorBgOpacity() {
    const bg = document.getElementById("editor-background");
    bg.style.opacity = editorBgOpacity / 100;
  }

  function updateEditorBackgroundPos() {
    const bg = document.getElementById("editor-background");
    const editor = document.getElementById("editor");
    bg.style.left = `${editor.offsetLeft}px`;
    bg.style.width = `${editor.offsetWidth}px`;
    bg.style.top = `${editor.offsetTop}px`;
    bg.style.height = `${editor.offsetHeight}px`;
  }
</script>

<svelte:head>
  <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
  <script
    defer
    src="https://cdn.quilljs.com/1.3.6/quill.js"
    on:load={initializeQuill}></script>
</svelte:head>
<svelte:window on:resize={updateEditorBackgroundPos} />
<div class="overlay-form">
  <div class="d-flex align-items-center">
    <h2 class="mb-3">
      {activeOverlay ? `${activeOverlay.streamerName}'s overlay` : ""}
    </h2>
    {#if lastUpdatedBy}
      <small class="text-secondary ms-3"
        >Updated just now by {lastUpdatedBy}</small
      >
    {/if}
  </div>
  <div id="editor-background" />
  <div id="editor-toolbar" />
  <div id="editor" />
  <div class="d-flex mt-3">
    <div>
      <label for="editor-bg-color" class="form-label">Background color</label>
      <input
        type="color"
        class="form-control form-control-color"
        id="editor-bg-color"
        bind:value={editorBgColor}
        on:input={updateEditorBgColor}
        title="Choose your color"
      />
    </div>
    <div class="ms-5">
      <label for="editor-bg-opacity" class="form-label"
        >Background opacity</label
      >
      <input
        type="range"
        class="form-range"
        id="editor-bg-opacity"
        min="0"
        max="100"
        bind:value={editorBgOpacity}
        on:input={updateEditorBgOpacity}
      />
    </div>
  </div>
  <div class="d-flex mt-3">
    <button class="btn btn-primary me-3" on:click={updateQuillValue}
      >Update Overlay</button
    >
    <button
      class="btn btn-outline-secondary"
      on:click={resetQuill}
      disabled={!isDirty}>Reset</button
    >
  </div>
</div>

<style>
  .overlay-form {
    width: 100%;
    max-width: 800px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 600px;
  }

  #editor {
    flex: 1;
    background-color: transparent;
  }

  #editor-background {
    position: absolute;
    background-color: var(--bs-dark);
  }

  :global(div.ql-editor) {
    color: var(--bs-white);
  }

  :global(div.ql-toolbar.ql-snow) {
    background-color: var(--bs-light);
  }
</style>
