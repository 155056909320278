// Components
import Home from "./Home.svelte";
import Overlay from "./Overlay.svelte";
import NotFound from "./NotFound.svelte";

// Export the route definition object
export default {
  "/": Home,
  "/overlay/:id": Overlay,

  // Catch-all, must be last
  "*": NotFound,
};
