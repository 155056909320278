<script>
  import firebase from "firebase/app";
  import "firebase/analytics";
  import "firebase/auth";
  import "firebase/firestore";
  import { createEventDispatcher, onDestroy } from "svelte";
  import { userState } from "./stores";

  const firebaseConfig = {
    apiKey: "AIzaSyAqdLaIrIwbbnzAQQ_KrwrP3mPpuAEmja8",
    authDomain: "moderator-managed-text-overlay.firebaseapp.com",
    projectId: "moderator-managed-text-overlay",
    storageBucket: "moderator-managed-text-overlay.appspot.com",
    messagingSenderId: "556705100700",
    appId: "1:556705100700:web:ab405a84b417d271880e59",
    measurementId: "G-HDK3WHT28G",
  };

  const dispatch = createEventDispatcher();

  firebase.initializeApp(firebaseConfig);
  const db = firebase.firestore();

  let modDocUnsub;

  onDestroy(() => {
    if (modDocUnsub) modDocUnsub();
  });

  firebase
    .auth()
    .signInAnonymously()
    .catch((error) => {
      console.error(`Error ${error.code}: ${error.message}`);
      dispatch("error", "Authentication system error.");
    });

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      console.log("Firebase user:", user.uid);
      const docRef = db.collection("mods").doc(user.uid);

      return docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Returning user. Set user state.");
            docRef.update({
              lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
            });
            subscribeToModAuth(user.uid);
          } else {
            console.log("Doc does not exist. Create.");
            docRef
              .set({
                uid: user.uid,
                username: null,
                overlay: null,
                lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then(() => subscribeToModAuth(user.uid));
          }
        })
        .catch((error) => {
          console.error("Error getting mod doc:", error);
          dispatch("error", "Database connection error.");
        });
    }
    dispatch("error", "Authentication system error.");
  });

  function subscribeToModAuth(uid) {
    modDocUnsub = db
      .collection("mods")
      .doc(uid)
      .onSnapshot(
        (doc) => {
          const userData = doc.data();
          console.log("Got mod doc", userData);
          userState.set(userData);
        },
        (error) => {
          console.error(error.message, error.code);
        }
      );
  }
</script>
