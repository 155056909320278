<script>
  import { createEventDispatcher } from "svelte";

  export let loggingIn = false;
  export let loginError = null;

  const dispatch = createEventDispatcher();
  let password;
  let username;

  function login() {
    console.log({ username, password });
    dispatch("login", {
      username,
      password,
    });
  }
</script>

<div class="password-form">
  <svg viewBox="0 0 135.46667 149.65125" version="1.1">
    <g transform="translate(-69.070886,-3.5660154)">
      <g transform="matrix(1.568436,0,0,-1.568436,-652.45029,592.90116)">
        <g clip-path="url(#clipPath3875)">
          <g transform="translate(301.7544,259.2451)" />
          <g transform="matrix(1.7058489,0,0,1.7058489,464.75451,308.71291)">
            <path
              d="m 0,0 v -8.197 c 0,-2.044 1.057,-3.874 2.828,-4.897 1.77,-1.021 3.883,-1.021 5.654,0 L 42.26,6.407 c 1.77,1.023 2.827,2.853 2.827,4.897 0,2.045 -1.057,3.875 -2.827,4.898 L 8.382,35.761 c -1.749,1.01 -3.839,1.01 -5.588,0 C 1.044,34.751 0,32.942 0,30.922 c 0,-4.665 3.794,-8.46 8.459,-8.46 h 9.466 c 6.193,0 11.232,-5.038 11.232,-11.231 C 29.157,5.039 24.118,0 17.925,0 Z m 5.655,-16.637 c -1.447,0 -2.895,0.38 -4.214,1.142 -2.638,1.523 -4.213,4.252 -4.213,7.298 V 2.772 h 20.697 c 4.664,0 8.459,3.795 8.459,8.459 0,4.665 -3.795,8.459 -8.459,8.459 H 8.459 c -6.193,0 -11.231,5.039 -11.231,11.232 -0.001,3.022 1.562,5.729 4.18,7.241 2.617,1.511 5.743,1.512 8.361,0 l 33.877,-19.56 c 2.639,-1.523 4.214,-4.252 4.214,-7.299 0,-3.047 -1.576,-5.775 -4.214,-7.298 L 9.869,-15.495 c -1.32,-0.762 -2.767,-1.142 -4.214,-1.142"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

  <h2 class="my-2">Password protected</h2>
  <form on:submit|preventDefault={login}>
    <div class="form-floating">
      <input
        type="text"
        name="username"
        id="username"
        required
        class="form-control"
        bind:value={username}
      />
      <label for="username">Chat Username</label>
    </div>
    <div class="form-floating">
      <input
        type="text"
        name="password"
        id="password"
        required
        class="form-control"
        bind:value={password}
      />
      <label for="password">Password</label>
    </div>
    {#if loginError}<small class="text-danger">{loginError}</small>{/if}
    <button
      type="submit"
      class="btn btn-primary w-100 d-flex justify-content-center align-items-center mt-3"
      disabled={!username || !password}
      >{#if loggingIn}<span
          class="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        />{/if}Sign in</button
    >
  </form>
</div>

<style type="text/scss" global>
  @import "./styles.scss";

  .password-form {
    text-align: center;
    width: 100%;
    max-width: 360px;
    padding: 12px;
    margin: auto;
    margin-top: 2rem;

    svg {
      fill: $primary;
      width: auto;
      height: 64px;
    }
  }
</style>
