<script>
  import firebase from "firebase/app";
  import Password from "./Password.svelte";
  import OverlayForm from "./OverlayForm.svelte";
  import { activeOverlay, userState } from "./stores";
  import { onDestroy } from "svelte";

  const db = firebase.firestore();

  // params is set by the svelte spa router
  export let params = {};

  $: {
    if (currentOverlayId !== params.id) {
      activeOverlay.set(null);
      subscribeToOverlayDoc();
      currentOverlayId = params.id;
    }
  }

  let currentOverlayId;
  let failedUpdate;
  let initialLoad = true;
  let isSubscribingToOverlay = false;
  let loginError;
  let loggingIn = false;
  let overlayDocUnsub;
  let showLogin = false;
  let successfulUpdate;
  let user;
  let userStateUnsub;

  onDestroy(() => {
    if (overlayDocUnsub) overlayDocUnsub();
    if (userStateUnsub) userStateUnsub();
  });

  // TODO: Handle use case where overlay is not password protected.
  userStateUnsub = userState.subscribe((u) => {
    user = u;
    if (!initialLoad && (!u.password || !u.overlay)) {
      showLogin = true;
    } else {
      // subscribeToOverlayDoc();
    }
  });

  function handleLogin(event) {
    loggingIn = true;
    loginError = null;
    db.collection("mods")
      .doc(user.uid)
      .update({
        username: event.detail.username,
        password: event.detail.password,
        overlay: params.id,
      })
      .then(() => {
        loggingIn = false;
        subscribeToOverlayDoc();
      })
      .catch((error) => {
        console.error("Failed to log in:", error);
      });
  }

  function handleUpdatedQuill(event) {
    console.log("Updating overlay:", event.detail);
    db.collection("overlays")
      .doc(params.id)
      .update(event.detail)
      .then(() => {
        successfulUpdate = true;
        setTimeout(() => (successfulUpdate = false), 5000);
      })
      .catch((error) => {
        console.error("Failed to update overlay:", error);
        failedUpdate = true;
        setTimeout(() => (failedUpdate = false), 5000);
      });
  }

  function subscribeToOverlayDoc() {
    if (isSubscribingToOverlay) return;

    isSubscribingToOverlay = true;
    if (overlayDocUnsub) overlayDocUnsub();

    overlayDocUnsub = db
      .collection("overlays")
      .doc(params.id)
      .onSnapshot(
        (doc) => {
          if (!doc.exists) {
            console.warn("Overlay does not exist");
          }
          console.log("Got overlay", doc.data());
          activeOverlay.set(doc.data());
          isSubscribingToOverlay = false;
          showLogin = false;
        },
        (error) => {
          if (error.code === "permission-denied") {
            console.warn("Invalid login.", error.message);
          } else {
            console.error("Actual error.", error);
          }

          if (!initialLoad) {
            loginError = "Invalid login. Double check overlay ID and password.";
          }

          initialLoad = false;
          isSubscribingToOverlay = false;
          overlayDocUnsub = null;
          db.collection("mods").doc(user.uid).update({
            password: firebase.firestore.FieldValue.delete(),
            overlay: firebase.firestore.FieldValue.delete(),
          });
          activeOverlay.set(null);
          showLogin = true;
        }
      );
  }
</script>

{#if showLogin}
  <Password on:login={handleLogin} bind:loggingIn bind:loginError />
{:else if $activeOverlay}
  <div class="overlay-form-container">
    {#if successfulUpdate}
      <div
        class="alert alert-success alert-dismissible mb-0"
        role="alert"
        on:click={() => (successfulUpdate = false)}
      >
        Successfully updated overlay!
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          on:click={() => (successfulUpdate = false)}
        />
      </div>
    {/if}
    {#if failedUpdate}
      <div
        class="alert alert-danger alert-dismissible mb-0"
        role="alert"
        on:click={() => (failedUpdate = false)}
      >
        Overlay failed to update. Waking up the hamsters. Try again in a minute.
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          on:click={() => (failedUpdate = false)}
        />
      </div>
    {/if}
    <OverlayForm
      activeOverlay={$activeOverlay}
      user={$userState}
      on:update={handleUpdatedQuill}
    />
  </div>
{:else}
  <div class="d-flex flex-column align-items-center mt-5 text-primary">
    <div class="spinner-border">
      <span class="visually-hidden">Loading</span>
    </div>
    <p class="mt-3">Summoning portal</p>
  </div>
{/if}

<style>
  .overlay-form-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 1rem;
  }
</style>
